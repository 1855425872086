import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Advanced_User/IOBroker_on_Raspberry_Pi/PrimaryBox';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "ioBroker Node-RED Adapter",
  "path": "/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Setup/Node-RED_Installation/",
  "dateChanged": "2018-09-20",
  "author": "Mike Polinowski",
  "excerpt": "ioBroker is an integration platform for the Internet of Things, focused on Building Automation, Smart Metering, Ambient Assisted Living, Process Automation, Visualization and Data Logging. Control your INSTAR IP camera with your Raspberry Pi. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "../../AU_SearchThumb_ioBroker.png",
  "social": "/images/Search/AU_SearchThumb_ioBroker.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_ioBroker_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "ioBroker"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='ioBroker Node-RED Adapter' dateChanged='2018-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='ioBroker is an integration platform for the Internet of Things, focused on Building Automation, Smart Metering, Ambient Assisted Living, Process Automation, Visualization and Data Logging. Control your INSTAR IP camera with your Raspberry Pi. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_ioBroker.png' twitter='/images/Search/AU_SearchThumb_ioBroker.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/IOBroker_auf_Raspberry_Pi/IOBroker_Setup/Node-RED_Installation/' locationFR='/fr/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Setup/Node-RED_Installation/' crumbLabel="ioBroker" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "iobroker-node-red-adapter",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#iobroker-node-red-adapter",
        "aria-label": "iobroker node red adapter permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ioBroker Node-RED Adapter`}</h1>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`ioBroker offers you `}<a parentName="p" {...{
        "href": "https://nodered.org"
      }}>{`Node-RED`}</a>{` as an easy to install adapter. Please be aware that we ran into a few problems here - that might not affect you. But we decided to use our earlier Node-RED Installation instead - so our setup now consists of two Rasperry Pi's - one with `}<a parentName="p" {...{
        "href": "/en/Advanced_User/openHABian_on_Raspberry_Pi/Node-RED_Setup/"
      }}>{`Node-RED`}</a>{` and `}<a parentName="p" {...{
        "href": "/en/Advanced_User/openHABian_on_Raspberry_Pi/Mosquitto_Setup/"
      }}>{`Mosquitto (MQTT Server)`}</a>{` installed and the other running ioBroker (and we will later add `}<a parentName="p" {...{
        "href": "/en/Advanced_User/IOBroker_on_Raspberry_Pi/motionEye/"
      }}>{`Motion`}</a>{`, which will add a significant load on this Pi - making it more sensible to have two Pi's running.).`}</p>
    <p>{`But for a simple setup - here is the guide on how to install Node-RED as an ioBroker Adapter:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b9fe69d487277e6f37b5130ee9567816/e9beb/IOB_Node-RED_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.782608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABPElEQVQY0wExAc7+AHOLn5uruVF1lVyj4G649mWy8W6z7pG42a/K36bF36fF3pjC5Vuw9Vmu9Fmv9Fqt82Gu8WOx81em6VKh5QB5jp6dq7lZfZ2DtN59ufCDuuyDuuyRvOORvea2z+bO3ObEzNuwudOzvNexu9WxyOOz1/G00u2w0OquzugAGjBCMU5oOFZzxs/X2+f04Onz3Oj02OTvxNTl5Ojt9PPw8urp7dvY8d7c8d/c+ejl99bT/Pj0//n1+vXxAJykq6u1vq23v9nS1Obb2e3t7PLw7uzq6N3a2eLi4uPj5Ofq6ebq6+fq6uPo6OTi4+fKzeLl5+vs7Ovr7QCd0f6TzP2X0P/Y4OPf3Nbc3d/o6enn5+bV1dbg4OHd3d7m5eXr6uvm5ubl5OTq6uvl6+zp6ens7Ozs7O09C+1PVFcqfAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b9fe69d487277e6f37b5130ee9567816/e4706/IOB_Node-RED_01.avif 230w", "/en/static/b9fe69d487277e6f37b5130ee9567816/d1af7/IOB_Node-RED_01.avif 460w", "/en/static/b9fe69d487277e6f37b5130ee9567816/b6582/IOB_Node-RED_01.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b9fe69d487277e6f37b5130ee9567816/a0b58/IOB_Node-RED_01.webp 230w", "/en/static/b9fe69d487277e6f37b5130ee9567816/bc10c/IOB_Node-RED_01.webp 460w", "/en/static/b9fe69d487277e6f37b5130ee9567816/87ca7/IOB_Node-RED_01.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b9fe69d487277e6f37b5130ee9567816/81c8e/IOB_Node-RED_01.png 230w", "/en/static/b9fe69d487277e6f37b5130ee9567816/08a84/IOB_Node-RED_01.png 460w", "/en/static/b9fe69d487277e6f37b5130ee9567816/e9beb/IOB_Node-RED_01.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b9fe69d487277e6f37b5130ee9567816/e9beb/IOB_Node-RED_01.png",
              "alt": "ioBroker Node-RED",
              "title": "ioBroker Node-RED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Go to the `}<strong parentName="p">{`Adapters`}</strong>{` tab and choose to install `}<strong parentName="p">{`node-red`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2db2e70bcda7cea8740c036d2b35672d/e9beb/IOB_Node-RED_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAIAAADJt1n/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC/klEQVQ4y42TXWsUVxjH56rfwA/QC+8Eb/wELUjR2loiJWBRixIbE62tvSh4aWnxQtBLwYpNsjGra1OTTUkCaQMVTcwLGqlrNms2e2b3nDPnPM858z6zxmRkJ5qs5KX98+PwwMzvzHnOwxj7Pj2eHZ24eC3z2aWB88NhZ94+N+xfGlrKT8yPzpZHZhfHn778aQxO3vfaB91vBt2zf7rfDbtn8/bRHmLs/ajl19zIj1d7j/yS7/grOXHP+TqLJ7P82xy90M/b79DTfbS1Rxzugi9SWrrhywwc7pJnctz4YNeHez45tvvjE52Xe37uuHKjbzJfCHMzKjuj78zozJTufqxvT6ns9Fv6ptTdaXX9geqe1EZXT29+9O/8yNjs07lCbxcUnr2ue3UP6h7WPXzl43JKc7ESoK2wVAXD1HXqLvuriRu/Aj/ggJQyyrgEAMRmJLxFACKio9C4OeXemPavPgqH/nUkq9Uo5ZbFORdCyPeT7rYRQDSYwCCMkiSJ41gppbVeX9eC76d5C8NxvSAMkyQJw5AQUq2alFJCKoQQ0ySmaa4fnFJaqVSEEBsytWQYxakcUSo4A86BUckatWAMhNDvkEIIAFjvwlh+vbK6upokSRQFaBf8qOiFRT8qun4ZsKTsZ25QdIN5N3ihtIWom1swkkYachh5hI6BM679B274jxfOSXyCzrgfT/rxhB8/VLqGsIWcrPW8sPCytLBISJVSjqhSNIBaAzdlQ46imElVE7YApbXCLV7eSY5qXFYtrAlFheJSbTetLWQ/jAmTQkjGLcYtyxJCCM4b1U5yetmJ7dcLZbZYfF6tLFqUCFZjjFmWBe/yHz2XKc6bWKrpErWXmF4bZvNgt/2ycKKLg+J8P3z/hzz3u7wyBkKC+p8XVo9jG6WtwFag09XRuB2ubvyVG3IQxWUGpoWEqwpH08IlhhWOZBMV3nhUtVJ5JT32HI1b++zPu50jGd162z5wy27JOId+s7/KOW0D7qn7DdoG3KM55+Atvf+m/cOQfgO0hEyn9e/OuAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2db2e70bcda7cea8740c036d2b35672d/e4706/IOB_Node-RED_02.avif 230w", "/en/static/2db2e70bcda7cea8740c036d2b35672d/d1af7/IOB_Node-RED_02.avif 460w", "/en/static/2db2e70bcda7cea8740c036d2b35672d/b6582/IOB_Node-RED_02.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2db2e70bcda7cea8740c036d2b35672d/a0b58/IOB_Node-RED_02.webp 230w", "/en/static/2db2e70bcda7cea8740c036d2b35672d/bc10c/IOB_Node-RED_02.webp 460w", "/en/static/2db2e70bcda7cea8740c036d2b35672d/87ca7/IOB_Node-RED_02.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2db2e70bcda7cea8740c036d2b35672d/81c8e/IOB_Node-RED_02.png 230w", "/en/static/2db2e70bcda7cea8740c036d2b35672d/08a84/IOB_Node-RED_02.png 460w", "/en/static/2db2e70bcda7cea8740c036d2b35672d/e9beb/IOB_Node-RED_02.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2db2e70bcda7cea8740c036d2b35672d/e9beb/IOB_Node-RED_02.png",
              "alt": "ioBroker Node-RED",
              "title": "ioBroker Node-RED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Set a basic login for the Node-RED Admin panel. The side navigation panel should now show a `}<strong parentName="p">{`node-red`}</strong>{`  tab that will lead you to the administration panel of Node-RED:`}</p>
    <h2 {...{
      "id": "iobroker-node-red",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iobroker-node-red",
        "aria-label": "iobroker node red permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><span parentName="h2" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACWElEQVQoz3WO7UsTcRzA70X/QX9AL3oRvQh6EQgiZfkq6EUgWSaFRVBQjZqbGoquOdBBaqQYSVgotsIXUmJO82FPbnPOzXlW2924Pdzubu283Z5u2932u2/Y+z6vPvB588FunGtcHJ7sej133bCgd4PWrnY7QW9XX26rQx4Y8oDJAzoHPNkCjQ0eb4JmA7Q2pHVC63QAO32+eebLsvnD0jX9uyud/S33Bi519jfc1F7s0DXf0Te1H0tjR09De8+FNl3zfUNb72Srbrzpbt/lBy+wEydPnWm5dfbqw9vPTBMjxqkx88SY2Wwa7O/p6n7+dNg4YOjr1mke9ek1A73awd6uidGRt29Gx4eNRpMBm/74aWFpbeHbmnMnUEdIqdUAoI4Q/B/0r+7iOJYrSuVKVUUqABwcktaNHX8wnKTpcJpzRyKeMPEnQzFJV5y0ityuyPDEfoShWEDqytYmRiVSvCDKslwHoP2+4PwcufGDYbn9Q9LmCuBEIkGskt4R/8HXGcfilHX912E0lxEBYM1hx4RcvlypAoCKgMiEXZQNZ0PCkcDEUxxJF/mslI0VuVA6X3TRgpdJV+p1UI83XX4/xvNihhflqqJU5VSM/ekjYuGkJElCIR9n2ShN56QqUqGuFKFWgFqpUslLpSrUwL0XwD5Pr1ssu7OzO67vQYGhiNA2HcVLkoSz6W0q7oklgqlUVhTj5BLuGw+6X0UO5v1eu3vFuul0YgzHF4plpYZkGTGsby/4PhJZlqSyVCrxmQzHcYqiIBWk3FHut4MPrWepEJ1cpaMWu9f2F3hezRB7EGx0AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/f95c5e0063750f9cf40c07e41d175909/e4706/IOB_Node-RED_04.avif 230w", "/en/static/f95c5e0063750f9cf40c07e41d175909/d1af7/IOB_Node-RED_04.avif 460w", "/en/static/f95c5e0063750f9cf40c07e41d175909/b6582/IOB_Node-RED_04.avif 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/avif"
          }}></source><source parentName="picture" {...{
            "srcSet": ["/en/static/f95c5e0063750f9cf40c07e41d175909/a0b58/IOB_Node-RED_04.webp 230w", "/en/static/f95c5e0063750f9cf40c07e41d175909/bc10c/IOB_Node-RED_04.webp 460w", "/en/static/f95c5e0063750f9cf40c07e41d175909/87ca7/IOB_Node-RED_04.webp 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/f95c5e0063750f9cf40c07e41d175909/81c8e/IOB_Node-RED_04.png 230w", "/en/static/f95c5e0063750f9cf40c07e41d175909/08a84/IOB_Node-RED_04.png 460w", "/en/static/f95c5e0063750f9cf40c07e41d175909/e9beb/IOB_Node-RED_04.png 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/en/static/f95c5e0063750f9cf40c07e41d175909/e9beb/IOB_Node-RED_04.png",
            "alt": "ioBroker Node-RED",
            "title": "ioBroker Node-RED",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      